<template>
  <b-container>
    <div class="content-detail-privilege">
      <div class="text-center" v-if="detail.image_url">
        <img :src="detail.image_url" class="w-100" />
      </div>
      <div class="text-center" v-else>
        <img :src="img" class="w-100" />
      </div>
      <div class="p-2" style="background-color: #3a3a3a">
        <div class="title-detail line-clamp-2">
          {{ detail.name }}
        </div>
        <b-row>
          <b-col cols="7">
            <div class="valide-until">
              วันหมดอายุ: {{ dateFormat(detail.valid_to) }}
            </div>
          </b-col>
          <b-col cols="5">
            <!-- <div v-if="detail.redeemable === 'Y'" class="status-reward">
              Available
            </div>
            <div v-else class="status-false">Unavailabe</div> -->
            <div v-if="detail.out_of_stock" class="status-false">
              ของรางวัลหมด
            </div>
            <div class="receive-point">
              แลกรับ {{ detail.point_used }} คะแนน
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="privilege-box-detail description">
        <div class="sub-description">รายละเอียด</div>
        <div class="p-3" v-html="detail.description"></div>
      </div>
      <b-row class="footer">
        <b-col class="text-center">
          <b-button
            class="w-100 btn-gray"
            :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
            @click="modalRedeem"
            :disabled="detail.redeemable === 'N' || detail.out_of_stock"
            >แลกแต้ม</b-button
          >
        </b-col>
      </b-row>
    </div>
    <ModalRedeem
      ref="modalRedeem"
      :detail="detail"
      @confirmRedeem="confirmRedeem"
    />
    <ModalRedeemAdress
      ref="modalRedeemAddress"
      :desciptionMsg="desciptionMsg"
      :btnMsg="btnMsg"
      :titleMsg="titleMsg"
      @submitModal="submitModal"
    />
    <ModalRedeemPoint
      ref="modalRedeemPoint"
      :detail="detail"
      :totalPoint="totalPoint"
      :address="addressList"
      @redeemSuccess="redeemSuccess"
      @redeemError="redeemError"
    />
    <ModalSelectBranch
      ref="ModalSelectBranch"
      :branch="branch"
      @success="success"
      @error="error"
      :detail="detail"
    />
    <ModalAlert ref="modalAlert" :text="msg" @hide="refreshPage" />
    <ModalError ref="modalError" :text="msg" />
  </b-container>
</template>

<script>
import ModalRedeem from "@/components/reward/modal/ModalRedeem";
import ModalRedeemAdress from "@/components/reward/modal/ModalRedeemAdress";
import ModalSelectBranch from "@/components/reward/modal/ModalSelectBranch";
import ModalRedeemPoint from "@/components/reward/modal/ModalRedeemPoint";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalError from "@/components/alert-modal/ModalError";
import picture from "@/assets/images/default-image.png";
import moment from "moment";
export default {
  components: {
    ModalRedeem,
    ModalAlert,
    ModalError,
    ModalSelectBranch,
    ModalRedeemAdress,
    ModalRedeemPoint
  },
  props: {
    detail: {
      required: true,
      type: Object
    },
    branch: {
      required: true,
      type: Array
    },
    addressFlag: {
      required: true,
      type: Boolean
    },
    id: {
      required: true,
      type: Number
    },
    totalPoint: {
      required: true,
      type: Number
    },
    addressList: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      img: picture,
      msg: "",
      typeRedeem: 2,
      desciptionMsg: "",
      btnMsg: "",
      titleMsg: ""
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    modalRedeem() {
      if (this.detail.pick_up_type_id === 1) {
        this.$refs.modalRedeem.show(true);
      } else if (this.detail.pick_up_type_id === 2) {
        if (this.addressFlag) {
          this.$refs.modalRedeemPoint.show(true);
        } else {
          this.desciptionMsg =
            "ของรางวัลจะจัดส่งทางไปรษณีย์ กรุณาตรวจสอบ/กรอกข้อมูลให้ครบถ้วน";
          this.btnMsg = "ถัดไป";
          this.$refs.modalRedeemAddress.show(true);
        }
      }
    },
    confirmRedeem(value) {
      this.$refs.ModalSelectBranch.show(true);
    },
    refreshPage() {
      window.location.href = "/reward";
    },
    success(value) {
      this.msg = value;
      this.$refs.modalAlert.show(true);
    },
    error(value) {
      this.msg = value;
      this.$refs.modalError.show(true);
    },
    submitModal() {
      if (this.addressFlag) {
        this.$router.push("/reward");
      } else {
        this.$router.push(`/editaddress/${this.id}`);
      }
    },
    redeemSuccess() {
      this.desciptionMsg =
        "ขอบคุณสำหรับการร่วมกิจกรรมและแลกของรางวัลกับทาง Pepsi Promotion กรุณารอรับสินค้าตามที่อยู่ที่ได้ลงทะเบียนในระบบ";
      this.btnMsg = "กลับหน้าหลัก";
      this.titleMsg = "แลกของรางวัลเสร็จสิ้น";
      this.$refs.modalRedeemAddress.show(true);
    },
    redeemError(data) {
      this.msg = data.detail;
      this.$refs.modalError.show(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.status-reward {
  color: lightgreen;
  font-weight: 600;
  font-size: 13px;
  text-align: right;
}
.status-false {
  color: red;
  font-weight: 600;
  font-size: 13px;
  text-align: right;
}
.title-detail {
  color: #fff;
  font-size: 18px;
  margin: 5 0px;
}
.line-clamp-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.valide-until {
  color: #fff;
  font-size: 13px;
}
.receive-point {
  color: #fff;
  text-align: right;
  font-size: 12px;
}
.privilege-box-detail {
  background-color: #fff;
  // height: 16em;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  font-weight: 600;
  border-bottom: 4px solid #000;
}
::v-deep a {
  color: gray;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
.footer-bottom {
  position: relative;
}
.btn-redeem {
  position: fixed;
  width: 90%;
  bottom: 10px;
  left: 20px;
  right: 10px;
  border: none;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}
.content-detail-privilege {
   margin: 1rem;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 15px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-gray {
  background: #000;
  border-color: transparent;
  color: #fff;
}
.description {
  background-color: #fff;
}
.sub-description {
  padding: 10px;
  border-bottom: 1px solid gray;
  font-weight: 600;
}
::v-deep p img {
  width: 100% !important;
  height: auto !important;
}
::v-deep img {
  width: 100% !important;
  height: auto !important;
}
::v-deep table {
  width: 100% !important;
  height: auto !important;
}
::v-deep tr {
  height: auto !important;
}
::v-deep td {
  height: auto !important;
}
</style>