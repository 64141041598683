<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="QRModal"
      ref="modalRedeem"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="my-3">
        <div class="modal-confirm-custom text-center">แลกแต้ม</div>
        <div>
          <div class="dot dot-left" style="left: 0; z-index: 1"></div>
          <div class="border-behind"></div>
          <div class="dot dot-right" style="right: 0"></div>
        </div>
        <b-row>
          <b-col class="text-center">
            <div class="text-center">
              <div>คุณต้องการแลกแต้ม {{ detail.point_used }} คะแนน</div>
              <div class="name-reward">
                เพื่อแลกรับ {{ detail.name }} หรือไม่?
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="border-top p-3 text-center">
        <b-row>
          <b-col>
            <b-button
              @click="close(1)"
              :disabled="isDisable"
              class="w-100 btn-confirm"
              :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import picture from "@/assets/images/default-image.png";
export default {
  props: {
    detail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      modalShow: false,
      img: picture,
      msg: "",
      isDisable: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close(status) {
      if (status === 1) {
        this.$emit("confirmRedeem", this.msg);
      }
      this.modalShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
.btn-confirm-popup {
  color: #fff !important;
}
.header-qr {
  border-bottom: 1px solid gray;
}
.modal-confirm-custom {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0;
  padding: 15px 0;
}
.name-reward {
  padding-top: 10px;
  font-weight: bold;
}
.btn-cancel {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}
.btn-confirm {
  // background-color: #000;
  border-color: #bfbfbf;
}
.dot {
  display: inline-block;
  top: 18%;
  position: absolute;
  width: 25px;
  height: 50px;
  border: 10px solid #757575;
  border-left: 0;
  background-color: #757575;
}
.dot-left {
  left: 0;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  right: 0;
}
.border-behind {
  border-top: 4px dashed #dfdfdf;
  position: absolute;
  top: 70px;
  width: 100%;
  left: 0;
}
</style>
